import "core-js/modules/es.array.push.js";
import { Dialog } from 'vant';
import 'vant/es/toast/style';
import { showDialog } from 'vant';
export default {
  data() {
    return {
      onshopping: false,
      active: 0,
      nowShows: false,
      value: '',
      totalmin: 0.00,
      totalmax: 0.00,
      show: false,
      bag: require('../../assets/bag.png'),
      banner: require('../../assets/banner.png'),
      shoppinglist: [],
      showList: [],
      items: [{
        text: '企业碳管理',
        children: [{
          name: '组织碳盘查',
          id: 1,
          desc: '为组织评估并报告其碳排放情况的服务。',
          price: '20000',
          num: 0,
          img: require('../../assets/a1.png'),
          deid: 1
        }, {
          name: '碳目标制定（SBTi）',
          id: 2,
          desc: '专业的SBTi科学碳目标一站式解决方案',
          price: '30000',
          num: 0,
          img: require('../../assets/a2.png'),
          deid: 2
        }, {
          name: '碳排放管理系统',
          id: 3,
          desc: '用于跟踪、分析和报告组织的碳排放情况的系统。',
          price: '200000',
          num: 0,
          img: require('../../assets/a3.png'),
          deid: 3
        }, {
          name: '供应链碳管理系统',
          id: 4,
          desc: '用于监控、分析和优化供应链中的碳排放情况的系统。',
          price: '200000',
          num: 0,
          img: require('../../assets/a4.png'),
          deid: 4
        }, {
          name: '碳减排决策管理系统',
          id: 5,
          desc: '辅助组织进行碳排放量减少决策和管理的系统。',
          price: '200000',
          num: 0,
          img: require('../../assets/a1.png'),
          deid: 5
        }, {
          name: '碳资产管理系统',
          id: 6,
          desc: '用于管理和优化组织的碳排放权和其他碳资产的系统。',
          price: '200000',
          num: 0,
          img: require('../../assets/a2.png'),
          deid: 6
        }, {
          name: '在线认证服务',
          id: 7,
          desc: '全球头部的第三方认证机构“打通，线上生成报告',
          price: '10000',
          num: 0,
          img: require('../../assets/a4.png'),
          deid: 7
        }]
      }, {
        text: '能碳ALOT',
        children: [{
          name: '零碳魔方-实时碳计量监控设备',
          id: 1,
          desc: '信息化解决企业碳排放数据精度差问题',
          price: '3000',
          num: 0,
          img: require('../../assets/b1.png'),
          deid: 8,
          type: 'more'
        }, {
          name: '大屏双碳可视化服务',
          id: 2,
          desc: '提供碳达峰和碳中和数据的可视化展示服务。',
          price: '10000',
          num: 0,
          img: require('../../assets/b2.png'),
          deid: 9
        }, {
          name: '机器人双碳可视化服务',
          id: 3,
          desc: '利用机器人技术提供碳达峰和碳中和数据的可视化展示服务。',
          price: '8000',
          num: 0,
          img: require('../../assets/b3.png'),
          deid: 10
        }, {
          name: '小程序双碳可视化服务',
          id: 4,
          desc: '通过小程序平台，提供碳达峰和碳中和数据的可视化展示服务。',
          price: '30000',
          num: 0,
          img: require('../../assets/b4.png'),
          deid: 11
        }, {
          name: '元宇宙双碳可视化服务',
          id: 5,
          desc: '在元宇宙环境中，展示和解析碳达峰和碳中和的数据。',
          price: '80000',
          num: 0,
          img: require('../../assets/b1.png'),
          deid: 12
        }, {
          name: 'AI能碳大数据服务',
          id: 6,
          desc: 'AI算法帮助企业构建各种脱碳减碳模型',
          price: '100000',
          num: 0,
          img: require('../../assets/b2.png'),
          deid: 13
        }, {
          name: '可信碳中和（区块链）',
          id: 7,
          desc: '利用区块链技术验证和追踪碳中和进度。',
          price: '100000',
          num: 0,
          img: require('../../assets/b3.png'),
          deid: 14
        }]
      }, {
        text: '产品碳足迹',
        children: [{
          name: '产品碳足迹盘查',
          id: 1,
          desc: '评估产品全生命周期内碳排放量并进行验证',
          price: '50000',
          num: 0,
          img: require('../../assets/c1.png'),
          deid: 15
        }, {
          name: '产品碳标签评价',
          id: 2,
          desc: '为产品或服务量身定制碳排放信息的标签服务。',
          price: '30000',
          num: 0,
          img: require('../../assets/c2.png'),
          deid: 16
        }, {
          name: '产品碳足迹模型系统开发',
          id: 3,
          desc: '开发用于评估产品碳排放的模型系统。',
          price: '300000',
          num: 0,
          img: require('../../assets/c1.png'),
          deid: 17
        }]
      }, {
        text: '碳资产开发与服务',
        children: [{
          name: 'CCER项目开发',
          id: 1,
          desc: '构建减排项目，获取碳排放减少额度收益',
          price: '25000',
          num: 0,
          img: require('../../assets/e1.png'),
          deid: 18
        }, {
          name: 'VCS项目开发与交易',
          id: 2,
          desc: '帮助企业VCS项目开发与交易',
          price: '25000',
          num: 0,
          img: require('../../assets/e2.png'),
          deid: 19
        }, {
          name: '国际绿证服务',
          id: 3,
          desc: '帮助有需求的企业进行匹配交易',
          price: '25000',
          num: 0,
          img: require('../../assets/e3.png'),
          deid: 20
        }]
      }, {
        text: '零碳内容服务',
        children: [{
          name: '零碳内容订阅',
          id: 1,
          desc: '提供碳圈CO2订阅式标准化服务',
          price: '25000',
          num: 0,
          img: require('../../assets/g1.png'),
          deid: 21
        }, {
          name: '双碳培训定制',
          id: 2,
          desc: '定制化企业碳中和内训和专题培训服务',
          price: '25000',
          num: 0,
          img: require('../../assets/g2.png'),
          deid: 22
        }, {
          name: '线上专家咨询',
          id: 3,
          desc: '碳圈CO2专家领衔，专业咨询团队提供实时在线客服服务',
          price: '25000',
          num: 0,
          img: require('../../assets/g3.png'),
          deid: 23,
          type: 'more'
        }]
      }, {
        text: '活动碳中和',
        children: [{
          name: '大型活动碳盘查/碳中和',
          id: 1,
          desc: '大型活动中抵消或减少该活动所产生的碳排放',
          price: '25000',
          num: 0,
          img: require('../../assets/h1.png'),
          deid: 24
        }, {
          name: '双碳活动策划',
          id: 2,
          desc: '为客户提供论坛活动设计规划',
          price: '25000',
          num: 0,
          img: require('../../assets/h2.png'),
          deid: 25
        }, {
          name: '碳中和会议',
          id: 3,
          desc: '组织并执行低碳或零碳排放的会议活动。',
          price: '10000',
          num: 0,
          img: require('../../assets/h1.png'),
          deid: 26
        }]
      }],
      itemsa: [{
        text: '零碳园区',
        children: [{
          name: '零碳园区',
          id: 1,
          desc: '通过绿色建筑和清洁能源等措施，建设低碳或零碳排放的园区。',
          price: '3000000~50000000',
          num: 0,
          img: require('../../assets/i1.png'),
          deid: 27
        }]
      }, {
        text: '零碳办公',
        children: [{
          name: '零碳办公',
          id: 1,
          desc: '零碳办公项目是指通过采取一系列措施，减少办公室排放温室气体，实现净零碳排放',
          price: '300000~500000',
          num: 0,
          img: require('../../assets/i2.png'),
          deid: 28
        }]
      }, {
        text: '实验室建设',
        children: [{
          name: '实验室建设',
          id: 1,
          desc: '协助他人建立碳中和实验室，推广低碳理念，助力企业绿色可持续发展',
          price: '25000~30000',
          num: 0,
          img: require('../../assets/a1.png'),
          deid: 29
        }]
      }, {
        text: '碳研究咨询',
        children: [{
          name: '碳研究咨询',
          id: 1,
          desc: '提供碳排放量分析，碳中和策略以及低碳技术的咨询服务',
          price: '25000~30000',
          num: 0,
          img: require('../../assets/b1.png'),
          deid: 30
        }]
      }, {
        text: '气候投融资',
        children: [{
          name: '气候投融资',
          id: 1,
          desc: '为气候友好项目和技术提供资金支持和投资策略咨询',
          price: '25000~30000',
          num: 0,
          img: require('../../assets/a3.png'),
          deid: 31
        }]
      }, {
        text: 'CCUS',
        children: [{
          name: 'CCUS',
          id: 1,
          desc: '涵盖二氧化碳捕捉、利用和储存技术的开发与实施。',
          price: '25000~30000',
          num: 0,
          img: require('../../assets/f1.png'),
          deid: 32
        }]
      }],
      activeIds: [1, 2],
      activeIdsa: [1, 2],
      activeIndex: 0,
      activeIndexa: 0,
      allList: []
    };
  },
  methods: {
    sptrolley() {
      this.onshopping = true;
    },
    addshopping(data) {
      if (this.shoppinglist.length > 0) {
        let arr = this.shoppinglist;
        this.shoppinglist.map(item => {
          if (item.deid != data.deid) {
            arr.push(data);
          }
        });
        this.shoppinglist = [...new Set(arr)];
        // for (var i = 0; i <= this.shoppinglist.length; i++) {
        //   console.log(this.shoppinglist[i])
        //   if (this.shoppinglist[i]?.deid != data.deid) {
        //       this.shoppinglist.push(data)
        //   }
        // }
      } else {
        this.shoppinglist.push(data);
      }
    },
    upshopping(data) {
      console.log(data);
      console.log(this.shoppinglist);
      this.shoppinglist.map((item, i) => {
        if (item.deid == data.deid) {
          this.shoppinglist.splice(i, 1);
        }
      });
    },
    nowShow() {
      console.log(2222);
      this.nowShows = true;
    },
    onSearch(e) {
      console.log(this.allList);
      console.log(this.value);
      let searchKey = this.value.split(',');
      console.log(searchKey);
      let arr = [];
      if (searchKey.length) {
        searchKey.map(it => {
          this.allList.map(item => {
            if (item.name.search(it) != -1) {
              arr.push(item);
            }
          });
        });
      }
      this.showList = [...new Set(arr)];
      console.log(this.showList);
    },
    cancel() {
      this.nowShows = false;
    },
    toCart(e) {
      console.log(11111);
      // this.$router.push('cart');
    },

    clickNav(e) {
      this.activeIndex = e;
    },
    getList(arr) {
      let newArr = [];
      arr.map(item => {
        if (item.children) {
          newArr = [...new Set(newArr.concat(item.children))];
        }
      });
      return newArr;
    },
    clickNava(e) {
      this.activeIndexa = e;
    },
    addNum(i) {
      this.addshopping(i);
      let min = i.price.split('~')[0];
      let max = i.price.split('~')[1];
      if (i.num == 0) {
        i.num += 1;
        if (max) {
          console.log(max);
          if (this.totalmax == 0) {
            this.totalmax += Number(max) + this.totalmin;
          } else {
            this.totalmax += Number(max);
          }
          console.log(this.totalmax);
        }
        this.totalmin += Number(min);
      } else {
        i.num = 1;
      }
    },
    reduceNum(i) {
      this.upshopping(i);
      let min = i.price.split('~')[0];
      let max = i.price.split('~')[1];
      if (i.num == 1) {
        i.num = 0;
        if (max) {
          this.totalmax -= Number(max);
        }
        this.totalmin -= Number(min);
        if (this.totalmin == this.totalmax) {
          this.totalmax = 0;
        }
      } else {
        i.num = 0;
      }
    },
    formatPrice() {
      return '¥' + (this.goods.price / 100).toFixed(2);
    },
    onClickCart() {
      this.$router.push('cart');
    },
    sorry() {
      if (this.nowShows) {
        this.nowShows = false;
        this.value = '';
      } else {
        showDialog({
          title: '',
          message: '请联系工作人员！',
          theme: 'round-button'
        }).then(() => {
          // on close
        });
        this.show = !this.show;
      }
    }
  },
  mounted() {
    this.allList = [...this.getList(this.items), ...this.getList(this.itemsa)];
    // let allList=this.getList(items)
    console.log(this.allList);
  }
};